<template>
	<div class="tw-content-section tw-grey-purple-bg-color p-3 text-white" v-permission="{juniper: 'view_dashboard_account_settings'}">
		<h4 class="tw-home-subscription-title text-center mt-2 fw-bold">{{ message }}</h4>
		<div class="p-5 text-center">
			<img src="@/assets/images/folder.svg" alt="Folder">
		</div>
		<h4 class="fw-bold">Your Package includes</h4>
		<div class="progress">
			<div class="progress-bar tw-red-bg-color" role="progressbar" :style="`width:${packagePercent}%`" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
		</div>
		<p class="mt-3">You are on the {{ subscription && subscription.package }} subscription<span v-if="this.dayLeft" class="d-inline"> and you have <b>{{ dayLeft }} day{{ (dayLeft == 1) ? '' : 's' }}</b> until it ends</span>!
		</p>
		<div v-if="requestResolved" class="tw-package-item-wrapper row mt-4">
			<div class="col-md-6 col-lg-12 col-xl-6 mt-3">
				<div class="tw-package-item d-flex align-items-center justify-content-between px-3" :class="tw_client ? 'tw-package-item-active' : 'tw-package-item-inactive'"><span>Town Web</span><i :class="tw_client ? 'fa-check' : 'fa-lock'" class="fas"></i></div>
			</div>
			<div class="col-md-6 col-lg-12 col-xl-6 mt-3">
				<div class="tw-package-item d-flex align-items-center justify-content-between px-3" :class="hg_client ? 'tw-package-item-active' : 'tw-package-item-inactive'"><span>HeyGov</span><i :class="hg_client ? 'fa-check' : 'fa-lock'" class="fas"></i></div>
			</div>
			<div class="col-md-6 col-lg-12 col-xl-6 mt-3">
				<div class="tw-package-item d-flex align-items-center justify-content-between px-3" :class="live_stream ? 'tw-package-item-active' : 'tw-package-item-inactive'"><span>Live Streaming</span><i :class="live_stream ? 'fa-check' : 'fa-lock'" class="fas"></i></div>
			</div>
			<div class="col-md-6 col-lg-12 col-xl-6 mt-3">
				<div class="tw-package-item d-flex align-items-center justify-content-between px-3" :class="hosting ? 'tw-package-item-active' : 'tw-package-item-inactive'"><span>Hosting</span><i :class="hosting ? 'fa-check' : 'fa-lock'" class="fas"></i></div>
			</div>
			<div class="col-md-6 col-lg-12 col-xl-6 mt-3">
				<div class="tw-package-item d-flex align-items-center justify-content-between px-3" :class="facebook_syndication ? 'tw-package-item-active' : 'tw-package-item-inactive'"><span>Facebook Sync</span><i :class="facebook_syndication ? 'fa-check' : 'fa-lock'" class="fas"></i></div>
			</div>
			<div class="col-md-6 col-lg-12 col-xl-6 mt-3">
				<div class="tw-package-item d-flex align-items-center justify-content-between px-3" :class="branded_emails ? 'tw-package-item-active' : 'tw-package-item-inactive'"><span>Branded Emails</span><i :class="branded_emails ? 'fa-check' : 'fa-lock'" class="fas"></i></div>
			</div>
		</div>
		<router-link class="btn tw-red-bg-color text-white w-100 mt-4 d-flex align-items-center justify-content-center" to="/account-settings/contract-info">Upgrade your Subscription</router-link>
	</div>
</template>

<script>
	import accountSettingsMixin from '@/mixins/accountSettingsMixin';
	export default {
		name: 'Subscription',
		data: () => ({
			subscription: {},
			totalPackagesNum: 6,
			packagePercent: 0,
			requestResolved: false,
			dayLeft: null,
			tw_client: false,
			hg_client: false,
			live_stream: false,
			hosting: false,
			facebook_syndication: false,
			branded_emails: false,
			message: "WELCOME TO YOUR NEW CLIENT DASHBOARD!",
		}),
		async mounted () {
			this.setSubscription();
		},
		watch: {
            subscription (val) {
				// val.contract_ends_at = true;
                if (val && val.contract_ends_at) {
					let eventdate = this.moment(val.contract_ends_at);
					// let eventdate = this.moment('2022-06-26');
					let todaysdate = this.moment();

                    this.dayLeft = eventdate.diff(todaysdate, 'days');
                }

                let activePackagesNum = 0;

                if (this.subscription && this.subscription.tw_client) {
					activePackagesNum++;
					this.tw_client = true;
                }
                if (this.subscription && this.subscription.hg_client) {
					activePackagesNum++;
					this.hg_client = true;
                }
                if (this.subscription && ['yes,not implemented', 'yes,implemented'].includes(this.subscription.live_stream)) { //yes,not implemented yes,implemented
					activePackagesNum++;
					this.live_stream = true;
                }
                if (this.subscription && this.subscription.hosting == 'yes') {
					activePackagesNum++;
					this.hosting = true;
                }
                if (this.subscription && ['yes,not implemented', 'yes,implemented'].includes(this.subscription.facebook_syndication)) {
					activePackagesNum++;
					this.facebook_syndication = true;
                }
                if (this.subscription && ['yes,not implemented', 'yes,implemented'].includes(this.subscription.branded_emails)) {
					activePackagesNum++;
					this.branded_emails = true;
                }
                if (this.subscription && this.subscription.dashboard_message) {
					this.message = this.subscription.dashboard_message;
                }


                this.packagePercent = Math.round((activePackagesNum/this.totalPackagesNum)*100);
            },
        },
		methods: {
			async setSubscription() {
				let res = await this.getGlobalInfo();

				this.requestResolved = true;

				this.subscription = res;
			},
		},
		mixins: [accountSettingsMixin]
	}
</script>
<style scoped>
	h4 {
		font-size: 22px;
	}
	img {
		width: 100%;
		/*max-width: 200px;*/
	}
	p {
		font-size: 18px;
	}
	a {
		font-size: 20px;
		font-weight: 700;
		border-radius: 5px;
		height: 65px;
	}
	.progress {
		height: 8px;
	}
	.progress-bar {
		border-radius: 4px;
	}
	.tw-package-item {
		height: 29px;
		border-radius: 14.5px;
		font-size: 14px;
	}
	.tw-package-item.tw-package-item-active {
		border: 1px solid var(--tw-light-green);
		color: var(--tw-light-green);
	}
	.tw-package-item.tw-package-item-inactive {
		border: 1px solid #D8D5D5;
		color: #D8D5D5;
	}
	@media only screen and (min-width: 1200px) {
		.tw-package-item-wrapper > div:nth-child(odd) {
			padding-right: 2px;
		}
		.tw-package-item-wrapper > div:nth-child(even) {
			padding-left: 2px;
		}
	}
</style>
